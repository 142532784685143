import RosettaSDK from '@product/rosetta-sdk'
import Cookies from 'js-cookie'
import { trackEvent, setVariables } from 'lib/gtm'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _isNil from 'lodash/isNil'
import { useCallback, useEffect } from 'react'
import { useStore } from 'store'
import { getUserRole } from 'utils/rosetta'

import {
  API_GW,
  CONTENT_API_KEY,
  D8_API_URI,
  PIANO_AID,
  PIANO_API_URI,
  PIANO_ENV,
  PIANO_ORIGIN,
  ROSETTA_CONTENTSERVICE_ENVIRONMENT,
  SUBSCRIBE_HOST,
  SUBSCRIBE_PROTOCOL,
} from '../../config/app.config'

const Rosetta = () => {
  const { rosettaState, loginState } = useStore()
  const subscriptionStatus = _get(rosettaState, 'state.subscriptionStatus', null)

  const rosettaOnReady = useCallback(() => {
    // Change this to use the SDK when we add support for checkout custom events
    const tp = window.tp || null
    if (tp) {
      tp.push([
        'addHandler',
        'checkoutCustomEvent',
        function (event) {
          switch (event.eventName) {
            case 'yp-meter-signin':
              loginState.dispatch({
                action: 'UpdateLoginPopupState',
                payload: { loginPopupState: true, triggerPoint: 'paywall' },
              })
              break
            default:
              break
          }
        },
      ])
    }
  }, [loginState])
  const rosettaOnExperienceExecute = useCallback(() => {
    RosettaSDK.rulesEngine.run()
  }, [])
  const rosettaOnShowOffer = useCallback((offerParams) => {
    const wrapperElement = document.querySelector(
      '.paywall-is-processing .piano-metering__paywall-container',
    )
    const processingArticle = document.querySelector('.paywall-is-processing')
    const elBody = document.querySelector('body')
    const displayMode = _get(offerParams, 'displayMode')

    if (displayMode === 'inline') {
      if (wrapperElement) {
        processingArticle.setAttribute('data-inline-wall', 'true')
        wrapperElement.classList.remove('hidden')
        wrapperElement.classList.add('inline-wall-rendered')
      }
    } else if (displayMode === 'modal') {
      if (elBody) {
        elBody.classList.add('offer-meter-open')
        const activeArticle = document.querySelector('.paywall-is-processing')
        if (processingArticle) {
          processingArticle.setAttribute('data-inline-wall', 'false')
        } else if (activeArticle) {
          activeArticle.setAttribute('data-inline-wall', 'false')
        }
      }
    }
    // Post processing tidy up
    if (processingArticle) {
      processingArticle.classList.remove('paywall-is-processing')
    }
    if (wrapperElement) {
      wrapperElement.classList.add('paywall-is-processed')
      wrapperElement.classList.remove('paywall-container')
    }
  }, [])
  const rosettaOnShowTemplate = useCallback(() => {}, [])
  const rosettaOnMeterActive = useCallback(() => {}, [])
  const rosettaOnMeterExpired = useCallback(
    (data) => {
      if (_get(data, 'metername') === 'YPMeter') {
        rosettaState.dispatch({ type: 'setMeterExpired' })
      }
    },
    [rosettaState],
  )

  const rosettaInit = useCallback(async () => {
    function parseContentServiceEnvironment(env) {
      switch (env.toLocaleLowerCase()) {
        case 'production':
          return 'production'
        default:
          return 'development'
      }
    }
    try {
      const scmpPat = Cookies.get('scmp_pat')
      const rosettaEnv = parseContentServiceEnvironment(ROSETTA_CONTENTSERVICE_ENVIRONMENT)

      RosettaSDK.setConfig({
        piano: {
          debug: false,
          aid: PIANO_AID,
          env: PIANO_ENV,
          apiUri: PIANO_API_URI,
          zone: 'yp',
        },
        drupal: {
          env: PIANO_ENV,
          apiUri: D8_API_URI,
          apiKey: CONTENT_API_KEY,
        },
        contentService: {
          apiUri: `${API_GW}/content-delivery/v1`,
          apiKey: CONTENT_API_KEY,
          env: rosettaEnv,
        },
        rosetta: {
          env: rosettaEnv,
          uri: `${SUBSCRIBE_PROTOCOL}://${SUBSCRIBE_HOST}`,
        },
      })

      if (!_isNil(scmpPat) && !_isEmpty(scmpPat)) {
        RosettaSDK.setToken(scmpPat)
      } else {
        rosettaState.dispatch({
          type: 'setSubscriptionStatus',
          payload: { subscriptionStatus: {} },
        })
      }

      await RosettaSDK.init({
        piano: {
          zone: 'yp',
        },
      })
      await RosettaSDK.initRules()

      try {
        const { supportPiano } = RosettaSDK.rulesEngine.getOutcome('guest')
        const user = RosettaSDK.rulesEngine.getOutcome('user')
        user &&
          rosettaState.dispatch({
            type: 'setSubscriptionStatus',
            payload: { subscriptionStatus: user },
          })
        rosettaState.dispatch({
          type: 'setIsShowRosetta',
          payload: { isShowRosetta: supportPiano },
        })

        const subscriberPhase = RosettaSDK.rulesEngine.getOutcome('tracking.ga4.subscriberPhase')
        rosettaState.dispatch({ type: 'setSubscriberPhase', payload: { subscriberPhase } })
      } catch (e) {
        console.error('[DEBUG]', 'RosettaSDK.rulesEngine.getOutcome', e)
      }
    } catch (error) {
      console.error('components/Rosetta::rosettaInit()', { error })
      rosettaState.dispatch({ type: 'setInitError' })
    }
  }, [rosettaState])

  const handlePianoTemplateTracking = (event) => {
    try {
      const origin = new URL(event.origin)
      if (origin.hostname !== PIANO_ORIGIN) {
        return
      }

      const eventData = _get(event, 'data', {})
      const eventType = _get(event, 'data.type', '')
      if (eventType === 'tracking') {
        const gtmData = _get(eventData, 'gtm', {})

        if (!_isEmpty(gtmData)) {
          const trackingEvent = _get(gtmData, 'tracking', {})
          const subscribeEvent = _get(gtmData, 'subscribe', {})

          if (!_isEmpty(trackingEvent)) {
            trackEvent(trackingEvent)
          }

          if (!_isEmpty(subscribeEvent)) {
            setVariables({
              subscribe: subscribeEvent,
            })
          }
        }
      }
    } catch (error) {
      console.error('ERROR in handlePianoTemplateTracking', error)
    }
  }

  const rosettaCommonChannel = useCallback((parameters) => {
    const { message } = parameters
    if (!message?.requestType) return
    switch (message.requestType) {
      case 'action/trackGA4': {
        setVariables({
          event: 'GA4_tracking',
          ...message.requestData,
        })
        break
      }
      case 'context/subscriberPhase': {
        message.responseData = RosettaSDK.rulesEngine.getOutcome('tracking.ga4.subscriberPhase')
        break
      }
      case 'tp/customVariables': {
        message.responseData = window.tp?.customVariables ?? {}
        break
      }
    }
    const { channel: _channel, ...messageWithoutChannel } = message
    RosettaSDK.sendMessage('yp/response-from-app', {
      ...parameters,
      message: messageWithoutChannel,
    })
  }, [])

  useEffect(() => {
    RosettaSDK.on('onReady', rosettaOnReady)
    RosettaSDK.on('experienceExecute', rosettaOnExperienceExecute)
    RosettaSDK.on('showOffer', rosettaOnShowOffer)
    RosettaSDK.on('showTemplate', rosettaOnShowTemplate)
    RosettaSDK.on('meterActive', rosettaOnMeterActive)
    RosettaSDK.on('meterExpired', rosettaOnMeterExpired)
    if (!RosettaSDK.isReady()) {
      rosettaInit()
    } else {
      rosettaOnReady()
    }

    RosettaSDK.registerListener('yp/request-from-template')
    RosettaSDK.on('COMM_CHANNEL', rosettaCommonChannel)

    window.addEventListener('message', handlePianoTemplateTracking, false)

    return () => {
      RosettaSDK.off('onReady', rosettaOnReady)
      RosettaSDK.off('experienceExecute', rosettaOnExperienceExecute)
      RosettaSDK.off('showOffer', rosettaOnShowOffer)
      RosettaSDK.off('showTemplate', rosettaOnShowTemplate)
      RosettaSDK.off('meterActive', rosettaOnMeterActive)
      RosettaSDK.off('meterExpired', rosettaOnMeterExpired)
      RosettaSDK.off('COMM_CHANNEL', rosettaCommonChannel)
      window.removeEventListener('message', handlePianoTemplateTracking, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // wait until subscriptionStatus is not null
    if (subscriptionStatus !== null) {
      const role = getUserRole(subscriptionStatus)
      if (role) {
        setVariables({ page: { userRole: role } })
      }

      const clientName = _get(subscriptionStatus, 'clientName', null)
      if (!_isEmpty(clientName)) {
        setVariables({ subscribe: { ipClientName: clientName } })
      }

      const subscriptionProducts = _get(subscriptionStatus, 'subscriptionProducts', [])
      if (!_isEmpty(subscriptionProducts) && subscriptionProducts.length > 0) {
        // only set the first one even if it returns multiple
        setVariables({ subscribe: { product: subscriptionProducts[0] } })
      }
    }
  }, [subscriptionStatus])

  return null
}

export default Rosetta
